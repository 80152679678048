import {
    CardHomeChart
} from "./styled";
import { LoadingContainer, NoDataContainer, NoDataIcon, NoDataText } from "../styled";

import { Chart } from "react-google-charts";

import DashboardCardWrapper from "../Wrapper";
import { theme } from "ui/theme-color";
import InsertChartIcon from '@mui/icons-material/InsertChart'; // Trocando o ícone
import CircularProgress from '@mui/material/CircularProgress'; // Importar o loading

export default function DashboardCardPaycheck({ register, filterMonth, loading }) {
    const getMonthAbbreviation = (monthNumber) => {
        const monthMap = {
            "01": "jan",
            "02": "fev",
            "03": "mar",
            "04": "abr",
            "05": "mai",
            "06": "jun",
            "07": "jul",
            "08": "ago",
            "09": "set",
            "10": "out",
            "11": "nov",
            "12": "dez"
        };
        return monthMap[monthNumber];
    };

    // Processar os dados do registro para todos os meses
    const monthsData = Array.from({ length: 12 }, (_, index) => {
        const monthNumber = String(index + 1).padStart(2, '0');
        const monthValue = register?.holeriteMonthCounts?.[index]?.[1] || 0;
        return {
            month: monthNumber,
            abbreviation: getMonthAbbreviation(monthNumber),
            value: filterMonth && monthNumber !== String(filterMonth).padStart(2, '0') 
                ? 0 
                : (typeof monthValue === 'string' ? parseFloat(monthValue) : monthValue)
        };
    });

    // Filtrar dados
    const filteredData = monthsData.filter(item => {
        if (filterMonth) {
            return true; // Mostra todos os meses, com valor 0 para não selecionados
        }
        return item.value > 0;
    });

    // Verificar se tem dados após a filtragem
    const hasData = filteredData.length > 0;

    // Preparar dados para o gráfico
    const chartData = hasData ? [
        ["Mês", "Valor"],
        ...filteredData.map(item => [item.abbreviation, Number(item.value)])
    ] : [];

    const options = {
        curveType: "function",
        legend: { position: "none" },
        colors: [theme.palette.primary.main],
        backgroundColor: theme.palette.colors.backgroundColor,
        tooltip: { trigger: 'both' },
        vAxis: {
            format: 'currency',
            formatOptions: {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2
            }
        },
        bar: { groupWidth: "30%" } // Mantém largura consistente
    };

    const formatOptions = {
        type: 'NumberFormat',
        column: 1,
        options: {
            prefix: 'R$ ',
            negativeColor: 'red',
            negativeParens: true,
            fractionDigits: 2,
            decimalSymbol: ',',
            groupingSymbol: '.'
        }
    };

    return (
        <DashboardCardWrapper title='Valor Líquido'>
            <CardHomeChart>
                {loading ? (
                    <LoadingContainer>
                        <CircularProgress size={40} />
                    </LoadingContainer>
                ) : hasData ? (
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="300px"
                        data={chartData}
                        options={options}
                        formatters={[formatOptions]}
                    />
                ) : (
                    <NoDataContainer>
                        <NoDataIcon>
                            <InsertChartIcon />
                        </NoDataIcon>
                        <NoDataText>
                            Nenhum registro encontrado
                        </NoDataText>
                    </NoDataContainer>
                )}
            </CardHomeChart>
        </DashboardCardWrapper>
    );
}
