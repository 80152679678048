import React, { useContext, useEffect, useState } from "react";

import {

} from "./styled";


import DashboardCardTabs from "components/Dashboard/Cards/Tabs";
import DashboardCardTable from "components/Dashboard/Cards/Table";
import { useHistory } from "react-router-dom";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, paycheckTypeOptions, typePaycheckOptions, typeSheetOptions } from "utils";
import { GeneratePdf, Read, ReadAll, ReadAllByUploadId, ReadAllForEmployee, ReadAllImports, ReadHoleritesGrouped, SignHolerite } from "services/holerites";
import { parseDatestringUSBR } from "utils/parsers";
import Accordion from "components/Accordion";
import BasicTable from "components/Form/Table";
import moment from "moment";
import { ReadEmployeeByUserId } from "services/employee.js";
import { toast } from "react-toastify";
import DashboardActions from "components/Dashboard/Actions";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export default function DashboardListPaycheck() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [activeTab, setActiveTab] = useState(0);
    const [uploadsRegister, setUploadsRegister] = useState([]);
    const { searchExpression, currentCompany, user } = useContext(CoreContext);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const options = [
        { id: 1, title: "Ver" }
    ];

    const tabs = [
        { title: 'Cadastros' },
        // { title:'Erros', badge:2 }
    ];

    const actionsSingle = [
        ...(user?.isManager ? [{
            title: "Download em LOTE",
            action: (uploadId) => downloadMassByUploadId(uploadId),
            loadable: true
        }] : []),
        ...(!user?.isManager ? [{
            title: "Assinar",
            action: (registerId) => downloadSingle(registerId),
            loadable: true
        }] : [])
    ];


    const downloadMassByUploadId = async (uploadId) => {
        try {
            const result = await ReadAllByUploadId(uploadId);
            const ids = result.data.map(row => row.id);

            if (ids.length > 0) {
                const blob = await GeneratePdf(ids);
                if (blob) {
                    const downloadUrl = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `holerites_${uploadId}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    throw new Error('Nenhum blob válido recebido para download.');
                }
            } else {
                toast.error("Nenhum registro encontrado para download.");
            }
        } catch (error) {
            console.error('Erro ao baixar os registros:', error);
            toast.error("Erro ao gerar o PDF: " + error.message);
        }
    };

    const downloadSingle = async (registerId) => {
        const userId = user?.id;
        const competence = rows[0]?.competence;

        const response = await ReadEmployeeByUserId(userId);
        const employeeId = response.data[0]?.id;

        if (!employeeId || !competence) {
            toast.error("Erro: Não foi possível determinar o ID do usuário ou a competência.");
            return;
        }

        const holeriteSigned = rows.find(row => row.id === registerId)?.isSigned;

        if (!holeriteSigned) {
            try {
                const response = await SignHolerite(employeeId, competence);
                console.log("🚀 ~ downloadSingle ~ response:", response);

                const updatedRows = rows.map(row => {
                    if (row.id === registerId) {
                        return {
                            ...row,
                            isSigned: true,
                            signedAt: moment().format("DD/MM/YYYY HH:mm") // Atualiza com a data e hora atuais
                        };
                    }
                    return row;
                });

                setRows(updatedRows);
                toast.success("Holerite assinado com sucesso!");
            } catch (error) {
                console.error("Erro ao assinar o holerite:", error);
                toast.error("Erro ao assinar o holerite: " + error.message);
                return;
            }
        }

        try {
            const blob = await GeneratePdf(registerId);
            if (blob) {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'holerites.pdf');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                throw new Error('No valid blob received for download.');
            }
        } catch (error) {
            console.error('Error downloading the PDF:', error);
            toast.error("Erro ao gerar o PDF: " + error.message);
        }
    };


    const actions = [
        // { title:'Cancelar', outline:true, action:() => history.goBack() },
        ...(user?.isManager ? [{ title: 'Novo', action: () => navigate("dashboard/paycheck/add") }] : [])
    ];

    const getActionsForEmployee = (isSigned) => {
        if (!isSigned) {
            return [
                {
                    title: "Visualizar e Assinar",
                    action: (registerId) => downloadSingle(registerId),
                    loadable: true
                }
            ];
        } else {
            return [
                {
                    title: "Visualizar",
                    action: (registerId) => downloadSingle(registerId),
                    loadable: true
                }
            ];
        }
    };

    const employeeColumns = [
        { title: 'Ano', ref: 'year' },
        { title: 'Mês', ref: 'month' },
        { title: 'Tipo de holerite', ref: 'type' },
        { title: 'Registro', ref: 'reg' },
        { title: 'Nome do funcionário', ref: 'name' },
        { title: 'CPF', ref: 'cpf' },
        { title: 'Salário base', ref: 'liquid' },
        { title: 'Data e hora da assinatura', ref: 'signedAt' },
        {
            title: "Assinaturas",
            ref: "isSigned",
            renderCell: (row) => {
                const isSigned = row.row.isSigned === true;
                return !isSigned ? <CloseIcon style={{ color: 'red' }} /> : <CheckIcon style={{ color: 'green' }} />;
            }
        },
        {
            title: "", ref: "",
            renderCell: (row) => {
                const isSigned = row.row.isSigned === true;

                const updatedActions = getActionsForEmployee(isSigned).map(action => ({
                    ...action,
                    action: () => {
                        action.action(row.row.id);
                    },
                    width: 'auto',
                    height: 'auto'
                }));

                return <DashboardActions actions={updatedActions} loading={row.loading} />
            }
        },
    ];

    const managerColumns = [
        { title: 'Ano', ref: 'year' },
        { title: 'Mês', ref: 'month' },
        { title: 'Tipo de holerite', ref: 'type' },
        { title: 'Data e hora da criação', ref: 'create_date' },
        { title: 'Usuário que importou', ref: 'importingUser' },
        {
            title: "", ref: "",
            renderCell: (row) => {
                console.log("🚀 ~ DashboardListPaycheck ~ row:", row)
                const updatedActions = actionsSingle.map(action => ({
                    ...action,
                    action: () => {
                        action.action(row.row.uploadId);
                    }
                }));

                return (
                    <DashboardActions
                        actions={updatedActions}
                        loading={row.loading}
                        onClick={(e) => e.stopPropagation()}
                    />
                );
            }
        }
    ];

    const columns = user?.isManager ? managerColumns : employeeColumns;



    const parseRegisterOwner = (holerite, uploadId) => ({
        ...holerite,
        create_date: moment(holerite.create_date).format("DD/MM/YYYY HH:mm"),
        type: typePaycheckOptions.find(option => option.id === holerite.type)?.title,
        sheetType: typeSheetOptions.find(option => option.id === holerite.sheetType)?.title,
        uploadId: uploadId,
        action: () => user?.isManager ? navigate(`dashboard/paycheck/form/${uploadId}`) : null
    });

    const parseRegister = item => {
        return {
            ...item,
            date: parseDatestringUSBR(item.createdAt),
            action: () => user?.isManager ? navigate(`dashboard/paycheck/form/${item.uploadId}`) : null,
            uploadId: item.uploadId,
            id: item.id,
            companyId: currentCompany?.id
        };
    };

    const init = async () => {
        setLoading(true);
        if (user?.isManager) {
            const holeriteResult = await ReadHoleritesGrouped(currentCompany?.id);

            if (holeriteResult) {
                const groupedByUploadId = holeriteResult.data.reduce((acc, group) => {
                    const { uploadId, holerites } = group;
                    acc[uploadId] = holerites.map(holerite => parseRegisterOwner(holerite, uploadId));
                    return acc;
                }, {});

                // Transform and sort the records
                const consolidatedHoleriteRegisters = Object.values(groupedByUploadId)
                    .flat()
                    .sort((a, b) => {
                        // Sort by year descending
                        if (b.year !== a.year) {
                            return b.year - a.year;
                        }
                        // If years are equal, sort by month descending
                        return parseInt(b.month) - parseInt(a.month);
                    });

                setRows(consolidatedHoleriteRegisters);
            }
        } else {
            const result = await ReadEmployeeByUserId(user?.id);
            const employeeId = result.data[0]?.id;
            const holeriteResult = await ReadAllForEmployee(employeeId);

            if (holeriteResult && !exposeStrapiError(holeriteResult)) {
                const normalHoleriteRegister = normalizeStrapiList(holeriteResult) || [];

                const filteredHoleriteRegisters = normalHoleriteRegister.filter(register => {
                    const companyIdField = register.data?.cnpjcpf || '';
                    return companyIdField === currentCompany.cnpj;
                });

                const consolidatedHoleriteRegisters = filteredHoleriteRegisters
                    .map(parseRegister)
                    .map(register => {
                        register.create_date = moment(register.create_date).format("DD/MM/YYYY HH:mm");
                        register.type = typePaycheckOptions.find(option => option.id === register.type)?.title;
                        register.sheetType = typeSheetOptions.find(option => option.id === register.sheetType)?.title;
                        register.reg = register.data?.reg || '';
                        register.name = register.data?.nome || '';
                        register.cpf = register.data?.cpf || '';
                        register.liquid = register.data?.funcionarioResumo?.liquido || '';
                        register.signedAt = register?.signedAt ? moment(register?.signedAt).format("DD/MM/YYYY HH:mm") : 'Data e Hora não disponivel';
                        register.signed = register?.isSigned;
                        return register;
                    })
                    .sort((a, b) => {
                        // Sort by year descending
                        if (b.year !== a.year) {
                            return b.year - a.year;
                        }
                        // If years are equal, sort by month descending
                        return parseInt(b.month) - parseInt(a.month);
                    });

                setRows(consolidatedHoleriteRegisters);
            } else {
                console.error('Error fetching or normalizing holeriteResult');
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        setRows([]);
        if (currentCompany?.id) {
            init();
        }
    }, [currentCompany]);

    return (
        <>
            <DashboardCardTabs title={"Holerites"} actions={actions} tabs={tabs} active={activeTab} setActive={setActiveTab} selectable={{ options, placeholder: "Ações" }}>
                {activeTab === 0 ? <DashboardCardTable rows={rows} actionsSingle={actionsSingle} columns={columns} searchExpression={searchExpression} pagination loading={loading} /> : null}
            </DashboardCardTabs>
        </>
    );
}