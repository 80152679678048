import { useContext, useEffect, useState } from "react";

import { } from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import DashboardCardHome from "components/Dashboard/Cards/Home";
import DashboardCardPaycheck from "components/Dashboard/Cards/Paycheck";
import { Read, ReadEmployeeMonthCounts, ReadHoleriteMonthCounts, ReadHoleriteMonthCountsForEmployee, ReadTotalDescontosForEmployee, ReadTotalVencimentosForEmployee } from "services/dashboards";
import { Read as ReadEmployee, ReadEmployeeByUserId } from "services/employee";
import { Read as ReadRequests } from "services/requests";
import { Read as ReadVacations } from "services/vacations";
import { Read as ReadEvents } from "services/event";
import { ReadAllForEmployee, ReadAll as ReadHolerites } from "services/holerites";
import { exposeStrapiError, normalizeStrapiList, typePaycheckOptions } from "utils";
import { CoreContext } from "context/CoreContext";
import DashboardCardEmployee from "components/Dashboard/Cards/Employee/index.js";
import DashboardCardLastSignature from "components/Dashboard/Cards/LastSignature/index.js";
import DashboardCardEvents from "components/Dashboard/Cards/Events";

export default function DashboardHome() {

    const { user, currentCompany, setCurrentCompany } = useContext(CoreContext)

    // console.log('currentCompany', currentCompany)

    const currentYear = new Date().getFullYear();

    const [filterYear, setFilterYear] = useState(currentYear);
    const [manualYearSet, setManualYearSet] = useState(false);
    const [filterType, setFilterType] = useState(false);
    const [filterMonth, setFilterMonth] = useState(null);
    const [manualMonthSet, setManualMonthSet] = useState(false);

    // console.log('filterYear', filterYear)

    const [loading, setLoading] = useState(false)
    const [register, setRegister] = useState(null)
    const [holeriteResult, setHoleriteResult] = useState([]);

    const [] = useState(null)

    const parseRegister = item => ({
        ...item
    })

    // Função para coletar todos os meses que têm dados de qualquer fonte
    const collectAvailableMonths = (data) => {
        const months = new Set();

        // Dados de holerite
        if (data?.holeriteMonthCounts) {
            data.holeriteMonthCounts.forEach((item, index) => {
                if (item[1] > 0) {
                    months.add(index + 1);
                }
            });
        }

        // Dados de funcionários
        if (data?.employeeMonthCounts) {
            data.employeeMonthCounts.forEach(([_, count], index) => {
                if (count > 0) {
                    months.add(index + 1);
                }
            });
        }

        // Dados de assinaturas
        if (data?.holeriteSignatures) {
            data.holeriteSignatures.forEach(signature => {
                if (signature.signedAt) {
                    const month = new Date(signature.signedAt).getMonth() + 1;
                    months.add(month);
                }
            });
        }

        // Adicionar outras fontes de dados aqui se necessário...

        return Array.from(months)
            .sort((a, b) => a - b)
            .map(month => ({
                value: String(month).padStart(2, '0'),
                label: new Date(2024, month - 1).toLocaleString('pt-BR', { month: 'long' })
            }));
    };

    const init = async () => {
        setLoading(true);
        try {
            if (user?.isManager) {
                const holeriteMonthCounts = await ReadHoleriteMonthCounts(filterYear, currentCompany?.id) || { data: [] };
                const employeeMonthCountsResponse = await ReadEmployeeMonthCounts(currentCompany?.id, filterYear);
                console.log('Employee counts response:', employeeMonthCountsResponse); // Para debug
                const employees = await ReadEmployee(currentCompany?.id) || { data: [] };
                const requests = await ReadRequests(currentCompany?.id) || { data: [] };
                const holerites = await ReadHolerites(currentCompany?.id) || { data: [] };
                const vacations = await ReadVacations(currentCompany?.id) || { data: [] };
                const events = await ReadEvents(currentCompany?.id) || { data: [] };

                const employeeMonthCounts = employeeMonthCountsResponse.data;

                const availableYears = [...new Set(employeeMonthCounts.map(item => item.year))];
                console.log("🚀 ~ init ~ availableYears:", availableYears);

                if (availableYears.length > 0 && !manualYearSet) {
                    const maxYear = Math.max(...availableYears);
                    setFilterYear(maxYear); 
                } else if (!filterYear) {
                    setFilterYear(currentYear);
                }


                if (holerites.data.length > 0) {
                    const availableYears = holerites.data
                        .map(hol => {
                            const competence = hol.attributes.competence; 
                            const year = parseInt(competence.substring(3, 7));
                            return isNaN(year) ? null : year; 
                        })
                        .filter(year => year !== null); 

                    console.log("🚀 ~ init ~ availableYears:", availableYears);

                    if (!manualYearSet && availableYears.length > 0) {
                        const maxYear = Math.max(...availableYears); 
                        setFilterYear(maxYear); 
                    }
                } else {
                    if (!manualYearSet) setFilterYear(currentYear); 
                }

                const abbreviateMonth = (monthName) => {
                    const monthMap = {
                        "january": "janeiro",
                        "february": "fevereiro",
                        "march": "março",
                        "april": "abril",
                        "may": "maio",
                        "june": "junho",
                        "july": "julho",
                        "august": "agosto",
                        "september": "setembro",
                        "october": "outubro",
                        "november": "novembro",
                        "december": "dezembro"
                    };
                    return monthMap[monthName.toLowerCase()] || monthName;
                };

                // Gerar a contagem por mês para o ano selecionado
                const employeeCounts = Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;
                    const count = employeeMonthCounts.find(item => item.month === month)?.count || 0;

                    const monthName = new Date(`${filterYear}-${month}-01`).toLocaleString('pt-BR', { month: 'long' });
                    const translatedMonth = abbreviateMonth(monthName);

                    return [translatedMonth, count];
                });
                console.log("🚀 ~ employeeCounts ~ employeeCounts:", employeeCounts)

                const holeriteCounts = Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;
                    const monthDate = new Date(filterYear, index, 1);
                    const monthName = abbreviateMonth(monthDate.toLocaleString('pt-BR', { month: 'long' }));
                    const totalLiquidSalary = holeriteMonthCounts.data?.find(item => parseInt(item.attributes.month) === month)?.attributes.totalLiquidSalary || 0;
                    return [monthName, parseFloat(totalLiquidSalary)];
                });

                const allMonths = Array.from({ length: 12 }, (v, i) => {
                    const date = new Date(new Date().getFullYear(), i);
                    return `${date.getMonth() + 1}-${date.getFullYear()}`;
                });

                const totalHoleritesSum = allMonths.map(month => {
                    const [m, year] = month.split('-');
                    const monthDate = new Date(year, m - 1, 1);
                    const monthName = abbreviateMonth(monthDate.toLocaleString('pt-BR', { month: 'long' }));

                    return {
                        month: monthName,
                        total: holeriteCounts.find(hc => hc[0].toLowerCase() === monthName.toLowerCase())?.[1] || 0
                    };
                });

                const extractedData = holerites.data.map(hol => ({
                    isSigned: hol.attributes.isSigned,
                    signedAt: hol.attributes.signedAt,
                    name: hol.attributes.name
                }));

                const result = await Read(`?filters[company]=${currentCompany?.id}&populate=*`);
                if (result && !exposeStrapiError(result)) {
                    const normalRegister = normalizeStrapiList(result) || [];

                    // Processar e setar o registro
                    const processedRegister = {
                        ...normalRegister.map(parseRegister)?.[0],
                        totalEmployees: employees?.data?.length,
                        totalRefund: requests?.data?.length,
                        totalSum: totalHoleritesSum,
                        holeriteSignatures: extractedData,
                        employeeMonthCounts: employeeCounts,
                        holeriteMonthCounts: holeriteCounts,
                        events: {
                            data: (events?.data || []).sort((a, b) => a.createdAt - b.createdAt).filter(f => f?.attributes?.is_dashboard)
                        },
                        employees: {
                            data: (vacations?.data || []).sort((a, b) => a.createdAt - b.createdAt).slice(0, 5)
                        },
                        requests: {
                            data: (requests?.data || [])
                                .filter(f => user?.isManager || f?.attributes?.cpf === user?.cpf)
                                .sort((a, b) => new Date(b?.attributes?.createdAt).getTime() - new Date(a?.attributes?.createdAt).getTime())
                                .slice(0, 5)
                        }
                    };

                    setRegister({
                        ...processedRegister,
                        data: employeeMonthCountsResponse.data // Passar os dados diretamente
                    });

                    // Coletar meses disponíveis de todas as fontes
                    const availableMonths = collectAvailableMonths(processedRegister);
                    
                    // Se não houver mês selecionado e houver meses disponíveis
                    if (!manualMonthSet && availableMonths.length > 0) {
                        setFilterMonth(parseInt(availableMonths[0].value));
                    }
                }
            } else {
                const holeriteMonthCounts = await ReadHoleriteMonthCountsForEmployee(filterYear, currentCompany?.id, filterType, filterMonth);
                const totalDescontos = await ReadTotalDescontosForEmployee(filterYear, currentCompany?.id, filterType, filterMonth);
                const totalProventos = await ReadTotalVencimentosForEmployee(filterYear, currentCompany?.id, filterType, filterMonth);

                const holeriteCounts = Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;
                    const monthDate = new Date(filterYear, index, 1);
                    const totalLiquidSalary = holeriteMonthCounts.data?.find(item => parseInt(item.attributes.month) === month)?.attributes.totalLiquidSalary || 0;
                    return [monthDate.toLocaleString('pt-BR', { month: 'long' }), parseFloat(totalLiquidSalary)];
                });

                const descontoCounts = Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;
                    const monthDate = new Date(filterYear, index, 1);
                    const totalDescontosValue = totalDescontos.data?.find(item => parseInt(item.attributes.month) === month)?.attributes.totalDescontos || 0;
                    return [monthDate.toLocaleString('pt-BR', { month: 'long' }), parseFloat(totalDescontosValue)];
                });

                const proventosCounts = Array.from({ length: 12 }, (_, index) => {
                    const month = index + 1;
                    const monthDate = new Date(filterYear, index, 1);
                    const totalProventosValue = totalProventos.data?.find(item => parseInt(item.attributes.month) === month)?.attributes.totalVencimentos || 0;
                    return [monthDate.toLocaleString('pt-BR', { month: 'long' }), parseFloat(totalProventosValue)];
                });

                const proventos = proventosCounts.reduce((acc, cur) => acc + cur[1], 0);

                const result = await ReadEmployeeByUserId(user?.id);
                const employeeId = result.data[0]?.id;
                const holeriteResult = await ReadAllForEmployee(employeeId);

                setRegister({
                    holeriteMonthCounts: holeriteCounts,
                    descontoCounts: descontoCounts,
                    proventos: proventos,
                    holeriteResult: holeriteResult.data
                });

                // Para usuário não manager, usar o primeiro mês com dados de holerite
                const availableMonths = holeriteMonthCounts.data
                    ?.filter(item => parseFloat(item.attributes.totalLiquidSalary) > 0)
                    ?.map(item => parseInt(item.attributes.month))
                    ?.sort((a, b) => a - b) || [];

                if (!manualMonthSet && availableMonths.length > 0) {
                    setFilterMonth(availableMonths[0]);
                }
            }
        } catch (error) {
            console.log("🚀 ~ init ~ error:", error);
        } finally {
            setLoading(false);
        }
    };







    useEffect(() => {
        if (currentCompany?.id) {
            init(); // Chama o `init` normalmente na inicialização
        }
    }, [currentCompany]);

    useEffect(() => {
        if (!manualYearSet) {
            setManualYearSet(true); // Marca que o ano foi definido manualmente
        }
    }, [filterYear]); // Escuta a mudança no `filterYear` manualmente    

    // Use um useEffect separado para escutar mudanças nos filtros sem redefinir o ano
    useEffect(() => {
        if (currentCompany?.id && filterYear) {
            init(false); // Permitir atualização com o ano definido pelo usuário
        }
    }, [filterYear, filterType, filterMonth]);

    useEffect(() => {
        if (!register || !register.holeriteResult || register.holeriteResult.length === 0) {
            if (!user?.isManager && typePaycheckOptions.length > 0) {
                setFilterType(typePaycheckOptions[0].id);
            }
        }
    }, [register, user?.isManager]);

    useEffect(() => {
        if (!manualMonthSet) {
            setManualMonthSet(true);
        }
    }, [filterMonth]);

    return (
        <>
            <ContainerAuthenticated page={"home"}>
                <Row>
                    {user?.isManager ? (
                        <Col sm={12} md={12}>
                            <DashboardCardHome 
                                register={register} 
                                filterYear={filterYear} 
                                setFilterYear={setFilterYear} 
                                filterMonth={filterMonth}
                                setFilterMonth={setFilterMonth} 
                            />
                            <DashboardCardPaycheck 
                                loading={loading} 
                                register={register} 
                                filterYear={setFilterYear}
                                filterMonth={filterMonth} 
                            />
                        </Col>
                    ) : (
                        <>
                            <DashboardCardHome
                                register={register}
                                filterYear={filterYear}
                                setFilterYear={setFilterYear}
                                filterType={filterType}
                                setFilterType={setFilterType}
                                filterMonth={filterMonth}
                                setFilterMonth={setFilterMonth}
                            />
                            <Col sm={4} md={6}>
                                <DashboardCardEvents loading={loading} register={register} />
                            </Col>
                            <Col sm={8} md={6}>
                                <DashboardCardPaycheck loading={loading} register={register} filterMonth={filterMonth} />
                            </Col>
                        </>
                    )}
                </Row>

                <Row>
                    {user?.isManager && (
                        <>
                            <Col sm={12} md={6}>
                                <DashboardCardEmployee 
                                    loading={loading} 
                                    register={register} 
                                    filterYear={setFilterYear} 
                                    filterMonth={filterMonth}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <DashboardCardLastSignature 
                                    register={register} 
                                    loading={loading} 
                                    filterMonth={filterMonth}
                                />
                            </Col>
                        </>
                    )}
                </Row>
            </ContainerAuthenticated>
        </>
    );
}