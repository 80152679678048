import styled from 'styled-components'


export const DashboardMenuContainer = styled.div.attrs({
})`           
    // position: fixed;
    // top:0;
    // bottom: 0;
    // right: 0;
    // left: 0;
    // z-index: 100;
    // background: ${props => props.theme.palette.colors.shadow};
`;

export const DashboardMenu = styled.div.attrs({
    className: 'menu-contant'
})`           
    max-width: 389px;
    min-width: 252px;
    max-width: 252px;
    background: ${props => props.theme.palette.colors.white};
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-right: 1px solid ${props => props.theme.palette.colors.lightshadow};
`;

export const DashboardMenuHeader = styled.div.attrs({
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${props => props.theme.palette.colors.white};
    cursor: pointer;

    background: ${props => props.theme.palette.primary.main};
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({
})`           
    padding: 27px 25px;
    margin-bottom: 28px;
    background: ${props => props.theme.palette.primary.main} ;
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({
})`           
    width: 160px;
    height: 160px; 
    border-radius: 80px; 
    background: ${props => props.theme.palette.colors.grey} url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({
})`           
    padding: 12px 8px;
    font-size: 14px;
    margin-bottom: 8px;
    border-radius: 8px;
    color: ${props => props.theme.palette.colors.lightgrey};
    cursor: pointer; 

    display: flex;
    align-items: center;
    gap: 12px;

    &:hover{
        text-decoration: underline;
    }
    
    ${props => props.active ? `
            background: ${props.theme.palette.primary.main};
            font-size: 14px;
            font-weight: bold;
            color: ${props.theme.palette.colors.white};
        ` : ``
    }
`;

export const DashboardMenuContent = styled.div.attrs({
})` 
    flex:1;
    padding: 16px;
`;

export const DashboardMenuFooter = styled.div.attrs({
})`
    padding: 16px;
`;

export const DashboardVersionContent = styled.div.attrs({
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({
})`
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    text-align: center; 
`;


export const DashboardLogoContainer = styled.div.attrs({
})`
    padding: 24px 16px 16px;
    border-bottom: 1px solid ${props => props.theme.palette.colors.lightshadow};
    margin: 0 0 16px;
`;
export const DashboardLogo = styled.img.attrs({
})`
    margin: 0 4px;
    width: 100%;
`;


export const DashboardMenuOptionIconDecoration = styled.div.attrs({
})`
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: ${props => props.active ? props.theme.palette.primary.main : props.theme.palette.colors.backgroundgrey};
`;

export const DashBoarMenuOptionWrap = styled.div`
    flex-direction: column;
`

export const DashboardMenuOptionStatus = styled.div`
    font-size: 12px;
    color: ${props => props.theme.palette.primary.main};  
    font-weight: bold;
    opacity: 0.7;  
    margin-top: 4px;
`;

export const DashboardMenuOptionIcon = styled.img.attrs(props => ({
    src: `/icons/side/${props.icon}-${props.active ? `off` : 'on'}.svg`
}))`
`;