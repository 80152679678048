import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    DropPaycheck,
    DropPaycheckIcon,
    FileName,
    FormActions,
} from "./styled";

import DashboardCardWrapper from "components/Dashboard/Cards/Wrapper";
import { monthsOptions } from "utils/brazil";
import DashboardActions from "components/Dashboard/Actions";
import DashboardFormsCore from "../Core";
import DashboardFeedback from "components/Dashboard/Feedback";
import UploadFile from "components/Form/UploadFile";
import { exposeStrapiError, normalizeStrapiRegister, numerize, typePaycheckOptions, typeSheetOptions, yearOptions } from "utils";
import { UploadImage } from "services/api";
import { toast } from "react-toastify";
import { Create, CreateLogImport, ReadAll, ReadHolerite, ReadOne, Update } from "services/holerites";
import { ReadOne as ReadOneCompany } from "services/companies";
import moment from "moment/moment";
import { Read, ReadEmployee } from "services/employee";
import { CoreContext } from "context/CoreContext";
import useEmployee from "hooks/useEmployee";
import { ConfirmationDialog } from "components/Modal";


export default function DashboardFormsPaycheckAdd() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const refForm = useRef()
    const { currentCompany, user } = useContext(CoreContext)
    // console.log('currentCompany', currentCompany)
    const { createEmployee, preRegisterEmployee } = useEmployee()

    const actions = [
        { title: 'Cancelar', outline: true, action: () => history.goBack() },
        { title: 'Salvar', action: () => save(), loadable: true },
    ]

    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [feedback, setFeedback] = useState(null)
    const [showReplaceModal, setShowReplaceModal] = useState(false);
    const [showLimitPayCheckModal, setShowLimitPayCheckModal] = useState(false);
    const [currentLimit, setCurrentLimit] = useState(0)

    console.log('currentLimit', currentLimit)


    const [formItems, setFormItems] = useState([])

    const maskCPF = (cpf) => {
        const digitsOnly = cpf.replace(/\D/g, '');
        return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }

    // console.log('currentLimit', currentLimit.limit)

    const generateUniqueUploadId = () => {
        const date = new Date();
        const uniqueId = date.getTime().toString() + Math.floor(Math.random() * 1000).toString();
        return uniqueId;
    };

    const save = async (e) => {
        try {
            const values = refForm.current.getForm();
            if (!(values['year'] && values['month'] && values['type'])) {
                toast.error("Preencha todos os campos");
                return;
            }
            if (!selectedFile) {
                toast.error("Selecione o arquivo");
                return;
            }

            setLoading(true);

            const result = await UploadImage(selectedFile, {
                type: "holerite",
                payload: {
                    'tipo-arquivo': currentCompany?.typeSheet,
                    'cnpj': currentCompany?.cnpj.replace(/\.|\-|\//g, ''),
                    'competencia': `${numerize(values['month'])}/${values['year']}`,
                    'tipo-holerite': values['type']
                },
            });

            // Check for API success response
            if (result.success) {
                const processInfo = result.processInfo;
                toast.success(`${processInfo.processedFiles} holerites processados com sucesso (${processInfo.successRate} de sucesso)`);
                history.goBack();
                return;
            }

            // Handle specific error cases
            if (result.error) {
                if (result.message?.includes("Holerite já existe com os dados fornecidos.")) {
                    setShowReplaceModal(true);
                } else if (result.message?.includes("Limite de funcionários atingido")) {
                    setShowLimitPayCheckModal(true);
                } else {
                    toast.error(result.message || "Ocorreu um erro durante o upload.");
                }
                return;
            }

            // Generic error handling
            toast.error("Erro ao processar o arquivo. Verifique se os dados estão corretos.");

        } catch (error) {
            console.error('Error during save process:', error);
            toast.error("Ocorreu um erro inesperado durante o processo.");
        } finally {
            setLoading(false);
        }
    };






    // const handleReplaceConfirm = async () => {
        
    // };

    const handleAcpetedChangePlan = () => {
        // console.log('mudar plano')
    }


    const ReplaceModalContent = () => (
        <>
            <p>Já existe um holerite lançado para este CPF e período. Deseja substituir o arquivo existente?</p>
            {selectedFile?.name ?
                <DropPaycheck onClick={() => setSelectedFile(null)}>{selectedFile?.name} <DropPaycheckIcon close /></DropPaycheck>
                :
                <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                    <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                </UploadFile>}
        </>
    );

    const LimitPaycheckModalContent = () => (
        <>
            <p>Você deve mudar de plano para conseguir cadastrar mais holerites</p>
            <strong>Você aceita mudar de plano?</strong>
        </>
    );



    useEffect(() => {
        setFormItems([
            { placeholder: "Ano", ref: "year", options: yearOptions, quarter: true },
            { placeholder: "Mês", ref: "month", options: [...monthsOptions], quarter: true },
            { placeholder: "Tipo de holerite", ref: "type", options: typePaycheckOptions, quarter: true },
            {
                placeholder: "Holerite",
                ref: "type",
                custom: selectedFile?.name ?
                    <DropPaycheck onClick={() => setSelectedFile(null)}><FileName>{selectedFile?.name}</FileName> <DropPaycheckIcon close /></DropPaycheck>
                    :
                    <UploadFile forwardRepo onChange={e => setSelectedFile(e)}>
                        <DropPaycheck><DropPaycheckIcon /> Adicionar arquivo </DropPaycheck>
                    </UploadFile>,
                quarter: true
            }
        ])
    }, [selectedFile])

    const init = async () => {
        const result = await ReadOneCompany(currentCompany?.id)
        const normalResult = normalizeStrapiRegister(result)
        const normalLimitPlan = normalizeStrapiRegister(normalResult?.limitPlan)
        setCurrentLimit(normalLimitPlan)
    };

    useEffect(() => {
        init();
    }, [currentCompany]);

    return (
        <>
            <DashboardCardWrapper backable title={"Novo holerite"} white>
                {feedback ? <DashboardFeedback message={"Holerites cadastrados com sucesso"} type={"success"} /> : null}
                <DashboardFormsCore formItems={formItems} ref={refForm} />
                <FormActions>
                    <DashboardActions actions={actions} loading={loading} />
                </FormActions>
            </DashboardCardWrapper>

            <ConfirmationDialog
                open={showReplaceModal}
                title="Substituir Holerite"
                onCancel={() => setShowReplaceModal(false)}
                onConfirm={save}
                btnCancelText='Cancelar'
                btnConfirmText='Confirmar'
                children={<ReplaceModalContent />}
            />

            <ConfirmationDialog
                open={showLimitPayCheckModal}
                title="Você ultrapassou o limite de funcionários!"
                onCancel={() => setShowLimitPayCheckModal(false)}
                onConfirm={handleAcpetedChangePlan}
                btnCancelText='Não'
                btnConfirmText='Sim'
                children={<LimitPaycheckModalContent />}
            />
        </>
    );
}
